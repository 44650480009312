<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="400 400 300 500"
    style="enable-background:new 0 0 1190.6 1683.8;"
    xml:space="preserve"
  >
    <path
      :class="parte ==='abdomen' || parte ==='otro' ? 'part_marked':''"
      d="M554.9,571.8c-0.1,73.7,70.6,62.6,70.6,62.6l0,0.2c0.8,6.1,1.3,9.2,1.3,9.2l0,0.1c-0.8,1.6-1.3,3.2-1.5,4.9
		c-1.1,10.6-3.2,21.1-3,31.7c0.2,13.6,2.1,27.2,3.5,40.7c0.8,7.3,1.8,14.6,3.2,21.8c1.3,6.9,3.4,13.7,4.8,20.5l0,0L609,780.8
		c-17.4,12.1-32.3,27.4-43.9,45.2l-10.4,15.9l0-0.1l-10-15.6c-11.6-17.8-26.5-33.1-43.9-45.2l-24.7-17.2l-0.1-0.1
		c1-4.9,1.9-9.8,2.9-14.8c2.8-14.9,4.4-30.2,8.6-44.7c4.1-14.1,7-27.9,4.4-42.6c-1.5-8.5-3-16.9-4.6-25.4l0-0.1c0,0,0.1-0.6,0.3-1.7
		l0,0C487.6,634.6,555,645.4,554.9,571.8"
    />
    <path
      :class="parte ==='pectoralizquierdo' || parte ==='otro' ? 'part_marked':''"
      d="M625.5,634.5c0,0-70.7,11.1-70.6-62.6c0.1-73.7,0-78.2,0-78.2l0-0.1c10.3,0.4,22.7-0.5,35.6-4.6
		c0.9,2.4,2.4,4.4,5.3,5.8c6.8,3.4,13.7,6.8,20.6,10c3.3,1.5,6.6,3,9.9,4.5l0,0c-8.1,55.3-3.5,105-0.9,125L625.5,634.5z"
    />
    <path
      :class="parte ==='pectoralderecho' || parte ==='otro' ? 'part_marked':''"
      d="M487.6,634.6c0,0,67.4,10.8,67.3-62.8c-0.1-73.7,0-77.7,0-77.7l0-0.5c-15.7-0.7-26.5-4.4-26.5-4.4
		s-5.3,6.2-9.7,8.2c-10.3,4.8-20.5,9.8-30.9,14.4l0,0.1c9.5,60.1,1.5,112.3-0.2,122.3L487.6,634.6z"
    />
  </svg>
</template>


<script>
export default {
  name: "Tronco",
  props: ["parte"],
};
</script>
<style lang="sass">
path
  fill: var(--v-gris1-base) !important
  &.part_marked
    fill: var(--v-gris2-base) !important
    stroke: #272727
    stroke-width: 0.5
    stroke-miterlimit: 10
.selected_inner
  path
    fill: var(--v-gris1-base) !important
    &.part_marked
      fill: var(--v-primary-base) !important
      fill: #E4BB6D
      stroke: #000000
      stroke-width: 0.5
      stroke-miterlimit: 10

#body_selector .v-card:hover
  path.part_marked
    fill: #E4BB6D !important
</style>